@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "AppRegularFont";
  font-weight: 300;
  src: local("AppRegularFont"),
    url(font/Proxima-Nova-Reg.ttf) format("opentype");
}

@font-face {
  font-family: "AppRegularFont";
  font-weight: 400;
  src: local("AppRegularFont"),
    url(font/Proxima-Nova-Reg.ttf) format("opentype");
}

@font-face {
  font-family: "AppRegularFont";
  font-weight: 500;
  src: local("AppRegularFont"), url(font/Proxima-Nova-Sbold.otf);
}

@font-face {
  font-family: "AppRegularFont";
  font-weight: 700;
  src: local("AppRegularFont"), url(font/Proxima-Nova-Bold.otf);
}
/* css */
.mantine-tbarly {
  overflow-y: auto;
}
